import { Component, Vue, Prop } from 'vue-property-decorator'
import Header from '@/components/header/Header.vue'
import BasicInput from '@/components/inputs/input/BasicInput.vue'
import Button from '@/components/button/Button.vue'
import Sidebar from '@/components/sidebar/Sidebar.vue'
import { Template } from '@/domain/entities/Template'
import { SplitTestTemplate } from '@/domain/entities/SplitTest'
import Route from 'vue-router'
import { create } from 'vue-modal-dialogs'
import BasicModal from '@/components/modals/basic-modal/BasicModal.vue'
import Confirmation from '@/components/modals/confirmation/Confirmation.vue'

const ShowBasicModal = create(BasicModal, 'title', 'content')
const ShowConfirmationModal = create(Confirmation, 'title', 'content')

@Component({
  components: {
    Sidebar,
    Header,
    BasicInput,
    Button,
    BasicModal,
    Confirmation
  }
})

export default class TriggerUpdate extends Vue {
  private searchString = ''
  private discardChanges = false
  private splitTestSaved = false
  private isTrafficValid = true
  private selectedTemplates: SplitTestTemplate[] = []
  private initialSelectedTemplates: SplitTestTemplate[] = [...this.selectedTemplates]

  @Prop({ required: true, type: String })
  readonly triggerName: string

  get emptyForm (): boolean {
    return this.selectedTemplates.length === 0
  }

  get templates (): Template[] {
    return this.$store.state.template.templates.filter((template: Template) => {
      return template.messageType === this.triggerName &&
        template.name.toLowerCase().includes(this.searchString.toLowerCase()) &&
        !this.selectedTemplates.find((splitTestTemplate: SplitTestTemplate) => {
          return splitTestTemplate.template.getMailerName() === template.getMailerName()
        })
    })
  }

  get totalTraffic (): number {
    return this.selectedTemplates.reduce(
      (acc: number, curr: SplitTestTemplate) => {
        return acc + curr.traffic
      },
      0
    )
  }

  public created () {
    if (this.$store.state.template.templates.length === 0) {
      this.$store.dispatch('getTemplates')
    }

    this.$store.dispatch('getSplitTest', {
      product: this.$store.state.product,
      trigger: this.triggerName
    }).then(() => {
      this.selectedTemplates = this.$store.state.splitTest.splitTest.templates
      this.initialSelectedTemplates = [...this.selectedTemplates]
    })
  }

  private addTemplate (template: Template) {
    this.selectedTemplates.push({
      template: template,
      traffic: 0
    })
  }

  private removeTemplate (index: number) {
    this.selectedTemplates.splice(index, 1)
  }

  private setTemplateTraffic (index: number, trafficValue: string) {
    this.selectedTemplates[index].traffic = parseInt(trafficValue)
  }

  private saveSplitTest () {
    if (this.totalTraffic !== 100) {
      ShowBasicModal('Warning', 'Total traffic must be 100% before saving')
      this.isTrafficValid = false
      return
    } else {
      this.isTrafficValid = true
    }

    this.$store.dispatch('updateSplitTest', {
      product: this.$store.state.product,
      trigger: this.triggerName,
      templates: this.selectedTemplates.map((splitTestTemplate: SplitTestTemplate) => {
        return {
          name: splitTestTemplate.template.getMailerName(),
          traffic: splitTestTemplate.traffic
        }
      })
    }).then(() => {
      this.splitTestSaved = true
      this.goIndexPage()
    }).catch(() => {
      ShowBasicModal('Error', 'Unexpected error when creating the split test')
    })
  }

  private goIndexPage () {
    this.$router.push({ path: '/triggers/index' })
  }

  public beforeRouteLeave (to: Route, from: Route, next: Function) {
    if (
      this.discardChanges ||
      this.splitTestSaved ||
      JSON.stringify(this.initialSelectedTemplates) === JSON.stringify(this.selectedTemplates)
    ) {
      next()
      return
    }

    this.showNoSaveModal()
    this.discardChanges = true
  }

  private async showNoSaveModal () {
    this.discardChanges = await ShowConfirmationModal(
      'Warning',
      'You have unsaved changes, please save before quitting'
    )
  }
}
